<template>
  <div
    class="tab-pane fade active show"
    id="bank-accounts"
    role="tabpanel"
    aria-labelledby="bank-accounts-tab"
  >
    <WeCard v-if="ready">
      <span class="btn btn-success mb-3" v-on:click="onCreate"
        ><i class="fas fa-plus"></i> Yeni Banka Hesabı</span
      >
      <WeTable
        v-bind:index="false"
        v-bind:data="list"
        v-bind:columns="columns"
        v-bind:actions="tableActions"
        v-on:on-action="onAction"
      ></WeTable
    ></WeCard>
    <WeCard v-else>
      <div style="min-height: 250px">
        <WeLoading />
      </div>
    </WeCard>
    <WeModal
      v-if="showModal"
      v-on:close="onClose"
      v-bind:title="getModalTitle"
      v-bind:title-image="getModalImage"
    >
      <div slot="body" style="min-height: 250px">
        <div v-if="ready">
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Banka Adı</span>
            </div>
            <div class="col-12 col-lg">
              <v-select
                label="name"
                v-bind:options="banks.list"
                v-bind:reduce="(b) => b.id"
                v-model="bankAccount.bank_id"
              ></v-select>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Şube Adı</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                type="text"
                v-bind:class="{ 'border-danger': $v.bankAccount.branch.$error }"
                v-model="bankAccount.branch"
                v-bind:formGroup="false"
              />
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Hesap Türü</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                type="text"
                v-bind:class="{
                  'border-danger': $v.bankAccount.account_type.$error,
                }"
                v-model="bankAccount.account_type"
                v-bind:formGroup="false"
              />
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Şube Kodu</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-bind:class="{
                  'border-danger': $v.bankAccount.branch_code.$error,
                }"
                v-model="bankAccount.branch_code"
              />
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Hesap No</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-bind:class="{
                  'border-danger': $v.bankAccount.account_number.$error,
                }"
                v-model="bankAccount.account_number"
              />
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">IBAN</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                class="mb-0"
                prepend="TR"
                maxlength="24"
                v-model="bankAccount.iban"
                v-bind:filter-number="true"
              />
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-lg-3">
              <span class="custom-label">Hesap Adı</span>
            </div>
            <div class="col-12 col-lg">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-bind:class="{
                  'border-danger': $v.bankAccount.account_name.$error,
                }"
                v-model="bankAccount.account_name"
              />
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="col-auto">
              <div class="form-group">
                <span class="btn btn-success" v-if="!submit" v-on:click="onSave"
                  ><i class="fas fa-save"></i> Kaydet</span
                >
                <span class="btn btn-success" v-else>
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <span class="btn btn-secondary" v-on:click="onClose"
                  >İptal</span
                >
              </div>
            </div>
          </div>
        </div>
        <WeLoading v-else />
      </div>
    </WeModal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "BankAccounts",
  data() {
    return {
      submit: false,
      ready: false,
      showModal: false,
      bankAccount: {},
      list: [],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "logo", icon: "fas fa-camera", type: "image_url", width: "5%" },
        { name: "name", th: "Banka Adı", type: "string" },
        { name: "branch", th: "Şube Adı", type: "string" },
        { name: "account_type", th: "Hesap Türü", type: "string" },
        { name: "branch_code", th: "Şube Kodu", type: "string" },
        { name: "account_number", th: "Hesap No", type: "string" },
        { name: "iban", th: "IBAN", type: "string" },
        { name: "account_name", th: "Hesap Adı", type: "string" },
      ],
    };
  },
  validations: {
    bankAccount: {
      bank_id: {
        required,
      },
      branch: {
        required,
      },
      account_type: {
        required,
      },
      branch_code: {
        required,
      },
      account_number: {
        required,
      },
      account_name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("bankaccount", [
      "create",
      "update",
      "delete",
      "getById",
      "getList",
    ]),
    ...mapActions("shared", ["getBankList"]),
    onAction(data) {
      if (data.key == "edit") {
        this.bankAccount.name = data.row.name;
        this.bankAccount.logo = data.row.logo;
        this.onEdit(data.row);
      }
      if (data.key == "remove") {
        this.onDelete(data.row);
      }
    },
    onEdit(row) {
      this.bankAccount = row; // TODO: Api tamamlanınca kaldırılacak
      this.showModal = true;
      this.getBankAccountById(row.id);
    },
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
      } else {
        this.submit = true;
        // Crate New Bank Account
        if (!this.bankAccount.id) {
          this.addBankAccount();
        } else {
          this.updateBankAccount();
        }
      }
    },
    onClose() {
      this.submit = false;
      this.showModal = false;
    },
    onCreate() {
      this.setDefault();
      this.showModal = true;
    },
    onDelete(row) {
      let message = row.account_name + " hesabı silinecek ?";

      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$swal({
                  title: "Banka Hesabı Başarıyla Silindi",
                  icon: "success",
                  confirmButtonText: "Tamam",
                });
                const index = this.list.findIndex((item) => item.id === row.id);
                this.list.splice(index, 1);
              } else {
                this.$swal({
                  title: "Banka Hesabı Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    setDefault() {
      this.$v.$reset();
      this.bankAccount = {
        id: null,
        logo: null,
        bank_id: null,
        name: null,
        branch: null,
        account_type: null,
        branch_code: null,
        account_number: null,
        iban: null,
        account_name: null,
      };
    },
    getBankAccountById(id) {
      this.getById({
        id: id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.bankAccount = this.filterBankAccountData(
              result.data.item,
              true
            );
            this.showModal = true;
            this.ready = true;
          } else {
            this.$toast.error("Bir Hata Oluştu");
          }
        },
      });
    },
    filterBankAccountData(item, detail = false) {
      const data = {};
      const bank = item.payment_bank ? item.payment_bank : null;

      if (bank) {
        data.bank_id = bank.id;
        data.logo = bank.logo ?? null;
        data.name = bank.name;
      }
      
      data.id = item.id;
      data.account_name = item.account_name;
      data.branch = item.branch_name;
      data.account_type = item.account_type;
      data.branch_code = item.branch_code;
      data.account_number = item.account_number;
      data.iban = item.iban && detail ? this.replaceIBAN(item.iban) : item.iban;

      return data;
    },
    replaceIBAN(iban) {
      if (iban.startsWith("TR")) {
        iban = iban.replace("TR", "");
      }
      return iban;
    },
    addBankAccount() {
      this.create({
        form: this.bankAccount,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            // this.bankAccount.id = result.data.id;
            // this.list.push(helper.clone(this.bankAccount));
            this.getBankAccounts();
            this.$toast.success("Banka Hesabı Eklendi");
          } else {
            this.$toast.error("Banka Hesabı Eklenemedi");
          }
        },
        onFinish: () => {
          this.onClose();
          this.setDefault();
        },
      });
    },
    // Update Bank Account
    updateBankAccount() {
      const dataIndex = this.list.findIndex(
        (data) => data.id === this.bankAccount.id
      );
      if (dataIndex >= 0) {
        this.list[dataIndex] = this.bankAccount;
      }
      this.update({
        id: this.bankAccount.id,
        form: this.bankAccount,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.getBankAccounts();
            this.$toast.success("Banka Hesabı Güncellendi");
          } else {
            this.$toast.error("Banka Hesabı Güncellenemedi");
          }
        },
        onFinish: () => {
          this.onClose();
          this.setDefault();
        },
      });
    },
    getBankAccounts() {
      this.ready = false;
      this.getList({
        onSuccess: (result) => {
          const items = result.data.items;
          if (items && items.length) {
            this.list = items.map((item) => {
              return this.filterBankAccountData(item);
            });
          } else {
            this.list = [];
          }
        },
        onFinish: () => (this.ready = true),
      });
    },
  },
  computed: {
    ...mapState({
      banks: (state) => state.shared.bank,
      config: (state) => state.session.config,
    }),
    getModalTitle() {
      return this.bankAccount.name || "Yeni Banka Hesabı";
    },
    getModalImage() {
      return this.bankAccount.logo || "";
    },
    siteUrl() {
      return helper.formatUrl(this.config["site.url"]);
    },
  },
  mounted() {
    this.setDefault();
    this.getBankList();
    this.getBankAccounts();
  },
};
</script>
