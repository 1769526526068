<template>
  <div class="tab-pane fade" id="pos" role="tabpanel" aria-labelledby="pos-tab">
    <WeCard>
      <WeTable
        v-bind:index="false"
        v-bind:data="pos.list"
        v-bind:columns="columns"
        v-bind:actions="tableActions"
        v-on:on-switch="onSwitch"
        v-on:on-action="onAction"
      ></WeTable
    ></WeCard>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Pos",
  data() {
    return {
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
      columns: [
        { name: "logo", icon: "fas fa-camera", type: "image_url", width: "5%" },
        { name: "bank_name", th: "Banka Adı", type: "string" },
        { name: "credit_card", th: "Kredi Kartı", type: "string" },
        { name: "is_default", th: "Ana Pos", type: "boolean" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
    };
  },
  methods: {
    ...mapActions("pos", ["getList", "isActive", "isDefault"]),

    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.push("/payments/pos/" + row.id);
    },
    onSwitch(data) {
      if (data.column == "is_default") {
        this.isDefault({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Ana Pos Durumu Güncellendi");

              if (data.is_active) {
                this.pos.list
                  .filter((item) => item.id !== data.id)
                  .map((item) => {
                    item.is_default = 0;
                  });
              }
            } else {
              this.$toast.error("Ana Pos Durumu Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Ana Pos Durumu Güncellenemedi");
          },
          onFinish: () => {
            this.pos.list.sort();
          },
        });
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
  },
  computed: {
    ...mapState(["pos"]),
  },
  mounted() {
    this.getList();
  },
};
</script>
