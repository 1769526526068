<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <!-- Bank Accounts -->
        <li class="nav-item" role="presentation">
          <a
            href="#bank-accounts"
            class="nav-link active"
            id="bank-accounts-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="bank-accounts"
            aria-selected="true"
          >
            <i class="fas money-check-alt"></i>
            <span class="ml-1">Banka Hesapları</span>
          </a>
        </li>
        <!-- ./Bank Accounts -->

        <!-- Pos -->
        <li class="nav-item ml-2" role="presentation">
          <a
            href="#pos"
            class="nav-link"
            id="pos-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="pos"
            aria-selected="true"
          >
            <i class="fas money-check-alt"></i>
            <span class="ml-1">Sanal Pos</span>
          </a>
        </li>
        <!-- ./Pos -->

        <!-- Collector Companies -->
        <li class="nav-item ml-2" role="presentation">
          <a
            href="#collection"
            class="nav-link"
            id="collection-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="collection"
            aria-selected="true"
          >
            <i class="fas money-check-alt"></i>
            <span class="ml-1">Tahsilat Kuruluşları</span>
          </a>
        </li>
        <!-- ./Collector Companies  -->
      </ul>
    </WeCard>
    <!-- Tabs -->
    <div class="tab-content">
      <BankAccounts />
      <Pos />
      <Collection />
    </div>
    <!-- Tabs -->
  </WeContainer>
</template>
<script>
import BankAccounts from "./views/BankAccounts/Index";
import Pos from "./views/Pos/Index";
import Collection from "./views/Collection/Index";
export default {
  name: "List",
  components: {
    BankAccounts,
    Pos,
    Collection,
  },
};
</script>
