<template>
  <div
    class="tab-pane fade"
    id="collection"
    role="tabpanel"
    aria-labelledby="collection-tab"
  >
    <WeCard>
      <WeTable
        v-bind:index="false"
        v-bind:data="collection.list"
        v-bind:columns="columns"
        v-bind:actions="tableActions"
        v-on:on-switch="onSwitch"
        v-on:on-action="onAction"
      ></WeTable
    ></WeCard>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Collection",
  data() {
    return {
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
      columns: [
        { name: "logo", icon: "fas fa-camera", type: "image_url", width: "5%" },
        { name: "name", th: "Firma Adı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
    };
  },
  methods: {
    ...mapActions("collection", ["isActive", "getList"]),
    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.push("/payments/integration-companies/" + row.id);
    },
    onSwitch(data) {
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
  },
  computed: {
    ...mapState(["collection"])
  },
  mounted() {
    this.getList();
  },
};
</script>
